export const hLetterArray = [
  [true, false, false, false, true],
  [true, false, false, false, true],
  [true, true, true, true, true],
  [true, false, false, false, true],
  [true, false, false, false, true],
]
export const iLetterArray = [
  [true, true, true, true, true],
  [false, false, true, false, false],
  [false, false, true, false, false],
  [false, false, true, false, false],
  [true, true, true, true, true],
]
export const maskArray = [
  [false, true, false, true, false],
  [true, false, true, false, true],
  [false, true, false, true, false],
  [true, false, true, false, true],
  [false, true, false, true, false],
]
